import React, { useState, useEffect } from 'react'
import { Form, Input, Select, DatePicker, Card } from 'antd'
import { LangContext } from '../../components/Translation'
import translations from './translations'
import Backend from '../../api/Backend'
import DecisionModal from '../../components/DecisionModal'

import AllowRender from '../../components/AllowRender'
import Skeleton from '../../components/Skeleton'
import Button from '../../components/Button'
import Description from '../../components/Description'
import Utils from '../../components/Utils'
import { useGlobalContext } from '../../components/AppContext'

import { toast } from 'react-toastify'
import moment from 'moment'

const MasterData = ({ id, data }) => {
	const [form] = Form.useForm()

	const backend = new Backend()
	const lang = React.useContext(LangContext)(translations)
	const utils = new Utils()
	const { isBetterCareProject } = useGlobalContext()

	const DATE_FORMAT = 'DD.MM.YYYY'

	const [patientData, setPatientData] = useState(null)
	const [patientPasswordResetModal, setPatientPasswordResetModal] = useState()

	let baseDataListValues = []
	let registrationDataListValues = ''

	const listValueTransformer = (value) => {
		if (value === true) {
			return lang('yes').toUpperCase()
		}
		if (value === false) {
			return lang('no').toUpperCase()
		}
		//Validate if the string is of a date format
		if (new Date(value) !== 'Invalid Date' && isNaN(value) && !isNaN(new Date(value))) {
			return utils.toDate({ dateString: value, withoutTime: true })
		}
		return value
	}

	if (data) {
		baseDataListValues = [
			{
				key: lang('full_name'),
				value: `${data && data.resource ? data.resource.name : lang('confidential')} ${data && data.resource ? data.resource.surname : ''}`
			},
			{
				key: lang('created'),
				value: ` ${utils.toDate({ dateString: data?.created_at })}`
			},
			{
				key: lang('TOS'),
				value: ` ${data?.terms_accepted === true ? lang('yes') : lang('no')}`
			},
			{
				key: lang('TOS_ON'),
				value: ` ${utils.toDate({ dateString: data?.terms_accepted_ts })}`
			},

			{
				key: lang('clinic'),
				value: ` ${data?.clinic_name}`
			}
		]

		//Add consent objects to the array of objects when there are properties from digital user registation for consents present
		//Otherwise it the baseDataListValues will render implicit statement of tos acceptance (In case of patients registration by a nurse through portal)
		if (data && data.consentsGiven && data.consentsGiven.length > 0) {
			data.consentsGiven.forEach((consent) => {
				baseDataListValues = baseDataListValues.filter((items) => items.key !== lang('TOS') && items.key !== lang('TOS_ON'))
				baseDataListValues.push({
					key: `${lang('consent_given_at', [consent.consent])}`,
					value: consent.acceptedAt ? `${utils.toDate({ dateString: consent.acceptedAt })}` : '-'
				})
			})
		}
	}

	if (data) {
		registrationDataListValues = []

		if (data && data.registrationData && data.registrationData.length > 0) {
			data.registrationData.forEach((registrationDetail) => {
				registrationDataListValues.push({
					key: registrationDetail.id,
					value: registrationDetail.value ? listValueTransformer(registrationDetail.value) : '-'
				})
			})
		}
	}

	useEffect(() => {
		backend.patient({
			id,
			cb: (d) => {
				setPatientData(d[0])
			}
		})
	}, [id])

	const handleSave = async () => {
		await form.validateFields()
		let fields = form.getFieldsValue()

		const body = JSON.stringify({ user_id: id, study_id: fields.study_id, ...fields })
		await backend.patientMasterData({
			type: Object.keys(patientData).length > 0 ? backend.type.UPDATE : backend.type.CREATE,
			body,
			cb: () => {
				toast.success('Patient data saved')
			}
		})
	}

	const rules = [
		{
			required: true,
			message: lang('missing-input')
		}
	]

	const formSheet = (
		<div>
			<Form
				form={form}
				initialValues={{
					study_id: data?.patient_name?.toUpperCase(),
					name: data?.resource?.name,
					surname: data?.resource?.surname,
					birthdate: moment(data?.resource?.birthdate),
					email: data?.resource?.email,
					consent: moment(data?.resource?.consent),
					zip: data?.resource?.zip,
					phone: data?.resource?.phone
				}}
				layout="vertical">
				<Form.Item name="study_id" label={lang('study_id')}>
					<Input disabled />
				</Form.Item>
				{data?.resource.consent ? (
					<Form.Item name="consent" label={isBetterCareProject() ? lang('consent_given_bettercare') : lang('consent_given')} rules={rules}>
						<DatePicker format={DATE_FORMAT} disabledPrevDays disabled />
					</Form.Item>
				) : null}

				<Form.Item name="name" label={lang('first_name')} rules={rules}>
					<Input />
				</Form.Item>
				<Form.Item name="surname" label={lang('surname')} rules={rules}>
					<Input />
				</Form.Item>
				<Form.Item name="birthdate" label={lang('date_of_birth')} rules={rules}>
					<DatePicker format={DATE_FORMAT} disabledPrevDay disabled />
				</Form.Item>
				<Form.Item
					name="email"
					label="Email"
					rules={[
						{
							required: true,
							type: 'email',
							message: lang('email_format')
						}
					]}>
					<Input disabled />
				</Form.Item>
				<Form.Item
					name="zip"
					label={lang('zip-code')}
					rules={[
						{ required: true, message: lang('missing-zip-code') },
						{
							validator(_, value) {
								if (!value.match(/^\d{5}$/)) {
									return Promise.reject(lang('invalid-zip-code'))
								} else {
									return Promise.resolve()
								}
							}
						}
					]}>
					<Input />
				</Form.Item>
				<Form.Item
					name="phone"
					label={lang('mob_phone')}
					rules={[
						{
							required: true,
							pattern: '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$',
							message: lang('phone_format')
						}
					]}>
					<Input />
				</Form.Item>
			</Form>
		</div>
	)

	const handlePatientPasswordReset = (data) => {
		if (!data) return

		const loadingToast = toast.loading(lang('request_processing'))

		backend.patients({
			id: data.id,
			type: backend.type.UPDATE,
			resetPassword: true,
			cb: () => {
				toast.update(loadingToast, { render: lang('password_reset_success'), type: 'success', isLoading: false, autoClose: true })
			}
		})
	}

	return (
		<>
			{/* {renderPatientListRedirect()} */}
			<div className="col-sm-12 col-lg-12">
				<Card>
					<Skeleton type="card" hide={baseDataListValues.length >= 1}>
						<Description
							data={baseDataListValues}
							emptyText={lang('no_data')}
							bordered
							title={lang('base_data')}
							layout="horizontal"
							column={{ md: 1 }}
						/>
					</Skeleton>
				</Card>
			</div>
			<div className="col-sm-12 col-lg-12">
				<Card>
					<Skeleton type="card" hide={Array.isArray(registrationDataListValues)}>
						<Description
							data={registrationDataListValues}
							emptyText={lang('no_registration_details')}
							bordered
							title={lang('registration_details')}
							layout="horizontal"
							column={{ md: 1 }}
						/>
					</Skeleton>
				</Card>
			</div>
			<div className="col-sm-12 col-lg-12">
				<Card
					title={lang('master_data')}
					extra={
						<AllowRender permissions={['PATIENT_RW']}>
							{data && data.resource?.disabled ? (
								<Button disabled type="disable" ghost txt={lang('temporarily_disabled')} />
							) : data?.resourceStudy?.status === 'ONBOARDING' || data?.resourceStudy?.status === 'PARTICIPATION' ? (
								<>
									<DecisionModal
										bordered
										open={patientPasswordResetModal}
										onOK={() => {
											handlePatientPasswordReset(patientPasswordResetModal)
											setPatientPasswordResetModal(undefined)
										}}
										onCancel={() => setPatientPasswordResetModal(undefined)}
										text={{
											title: lang('reset_password'),
											ok: lang('reset'),
											cancel: lang('cancel'),
											details: `${lang('reset_password_for', [patientPasswordResetModal?.resource?.email])}`
										}}
									/>
									<Button
										type="reset"
										ghost
										txt={lang('reset_password')}
										onClick={() => setPatientPasswordResetModal(data)}
										className=""
									/>
								</>
							) : null}
						</AllowRender>
					}>
					<>
						<Skeleton type="card" hide={patientData}>
							<div className="card-body">
								{formSheet}
								<div style={{ background: '#ffff' }} className="card-footer text-end">
									<AllowRender permissions={['PATIENT_RW']}>
										<div className="d-flex">
											<Button type="submit" onClick={handleSave} className="ant-btn ant-btn-primary ms-auto">
												{lang('save')}
											</Button>
										</div>
									</AllowRender>
								</div>
							</div>
						</Skeleton>
					</>
				</Card>
			</div>
		</>
	)
}

export default MasterData
